<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row class="bradcrumb">
            <router-link :to="{name: 'super-admin-home' }" class="root">
                Dashboard
            </router-link>
            /
            <router-link :to="{name: 'super-master-main' }" class="root">
               Super Admin Master
            </router-link>
            <span class="child">/ Notification Method </span>
        </v-row>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Notification Method</span>
            </v-card-title>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                            <label class="add-text">Add New Notification Method</label>
                            <v-text-field class="text" v-model="nm" outlined dense :rules="[rules.required]" clearable required></v-text-field>
                            <v-btn color="success" @click="addnm">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <v-card>
                                <v-card-title class="grey lighten-2">
                                    Edit Notification Method
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Notification Method</label>
                                    <v-text-field class="text" v-model="nmName" outlined dense :rules="[rules.required]" clearable required></v-text-field>
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" color="error" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" color="success" @click="saveEdit()">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>

                <template v-if="nm_list!=null">
                    <v-data-table :headers="headers" :items="nm_list" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updatenm(item)">mdi mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.isactive" @change="editItem(item)"></v-switch>
                        </template>
                    </v-data-table>
                </template>
            </v-card-text>
        </v-card>
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
    import axios from "axios";
    import "@/assets/css/color.css"; // css needs to be imported for each dashboard
    export default {
        data: () => ({
            overlay:false,
            snackbar_msg: "",
            snackbar: false,
            color: "",
            org: null,
            dialog: false,
            search: "",
            nm:null,
            nmId:null,
            nmName:null,
            userType: null,
            edit_btn_load: false,
            rules: {
                required: (value) => !!value || "Required.",
            },
            nm_list: null,
            headers: [
                { text: "Sr no", value: "srno" },
                { text: "Name", value: "nm" },
                { text: "Edit", value: "edit" },
                { text: "Toggle", value: "actions", sortable: false },
            ],
        }),
        watch: {},
        methods: {
            showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
            editItem(item) {
                console.log(item);
                axios
                    .post("/SuperAdmin/nMethodIsactive", item)
                    .then(() => {
                        // this.sec_mode_list = res.data.user_list;
                        this.onload()
                        this.showSnackbar("#4CAF50", "Notification Method updated successfully...");
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        axios;
                    });
            },

            updatenm(item) {
                this.dialog = true;
                this.nmName = item.nm;
                this.nmId = item.id;
            },

            saveEdit(item) {
                console.log(item);
                if(this.nmName){
                this.nmName = this.nmName.trim()
                }
                const data = {
                    nmName: this.nmName,
                    nmId: this.nmId,
                };
                if (this.nmName) {
                    axios
                        .post("/SuperAdmin/editNotifyMethod", data)
                        .then((res) => {
                            if (res.data.status == "YES") {
                            //this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "Account status updated successfully...");
                            this.onload();
                            this.dialog = false;
                            } else {
                                this.showSnackbar("#b71c1c", res.data.msg);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {
                            this.edit_btn_load = false;
                            // this.editUserType = null;
                            axios;
                        });
                } else {
                    this.showSnackbar("#b71c1c", "Please enter Account status");
                }
            },

            onload() {
                this.overlay = true
                axios
                    .post("/SuperAdmin/getNotifyMethodData")
                    .then((res) => {
                        this.org = res.data.org;
                        this.nm_list = res.data.methods_list;
                        this.overlay = false
                        //this.showSnackbar("#4CAF50","Success")
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.overlay = false
                    });
            },

            addnm() {
                if(this.nm){
                this.nm = this.nm.trim()
                }
                const data = {
                    nm_name: this.nm,
                };

                if (this.nm) {
                    axios
                        .post("/SuperAdmin/saveNotifyMethod", data)
                        .then((res) => {
                            if (res.data.status == "YES") {
                                this.onload();
                                this.showSnackbar("#4CAF50", "Notification method added successfully");
                            } else {
                                this.showSnackbar("#b71c1c", res.data.message);
                            }
                        })
                        .catch((error) => {
                            this.showSnackbar("#b71c1c", "Something went wrong!!!");
                            console.log(error);
                        })
                        .finally(() => {});
                } else {
                    this.showSnackbar("#b71c1c", "Please enter Account status");
                    this.$refs.form.validate()
                }
            },
        },
        mounted() {
            this.onload();
        },
    };
</script>
<style scoped>
    .heading {
        background-color: #3f51b5;
        padding: 0.3rem;
        color: white;
    }
    .v-icon {
        cursor: pointer;
    }
    .text >>> .v-input__slot {
        background: white !important;
        border-color: hsla(190, 99%, 30%, 1) !important;
    }
</style>
